.hero {
    max-height: 478px;
}

.literacysuperhero {    

margin-bottom: auto;    
text-align: left;
font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:35px;color:#ffff00;font-weight:bold;
height:350px;
text-shadow: 0.5px 0.5px #ff0000;
padding: 15px;   

float: left;
position: absolute;
z-index: 1000;
}

.contactme-container {    
    padding: 15px;   
    float: left;
    position: absolute;
    top: 270px;
    z-index: 1000;
    }

  
.jackalopeman {
    text-align: left;
    margin-top: -2px;
    font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:20px;color:#ffffff;font-weight:bold;
    width: auto;
    
    }

.authorpoet {
    text-align: left;
    margin-top: -15px;
    font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;font-size:20px;color:#ffff00;font-weight:normal;
    font-style:italic;
    width: 100%;
    
    }
img.heroimage {
    width: 100%;
    max-height: 478px;
}

img.contactme {
    background-color: #FF0000;
    width: 20vw;
    height: auto; 
    border:0px solid #ffffff;
    border-radius:5px;
    padding: 6px;
    
  }

.imbruce-container {

    background-color: #1C92D2;
    border:2px solid #ffffff;
    border-radius:5px;
    padding: 10px;  
    }

p.imbruce {
    line-height: 1.4;
    font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;
    font-size:18px;color:#ffffff;
    font-weight:bold;
    font-style:normal;
    text-decoration:none;
    text-align:left;
    text-shadow:0px 0px black;
}

.contact-container {
    justify-content:center;
    text-align:center;
    background-color: #1C92D2;
    border:2px solid #ffffff;
    border-radius:5px;
    padding: 10px;  
    margin-top:10px;
    }
.contact-image-wrapper{
    /* width: 100%; */
    height: auto;
    text-align: center;
    margin-bottom: 20px;

}  
.contact-image{
    
    width: 75px;
    height: auto;

}  
.contact-box {
    padding: 10px;  
    float: left;
    height: 150px;
    margin: 5px;
    font-weight:bold;
    text-align: center;
}

.contact-text{
    margin: auto;
    text-align: center;
    width: 70%;
}
.contact-message-wrapper{
    margin: auto;
    display:flex;
    min-width: 50%
}
p.contact-message {
    line-height: 1.4;
    font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;
    font-size:18px;color:#ffffff;
    font-weight:bold;
    font-style:normal;
    text-decoration:none;
    text-align:left;
    text-shadow:0px 0px black;
}


@media (max-width: 1080px) {
    .literacysuperhero {    
        font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:30px;color:#ffff00;font-weight:bold;
        padding: 15px;
      }

    .hero {
        max-height: 346px;
    }
    .jackalopeman {
    margin-top: -2px;
    font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:20px;color:#ffffff;font-weight:bold;

    
    }
    .authorpoet {
        margin-top: -15px;
        font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;font-size:20px;color:#ffff00;font-weight:normal;
        
        }
    
    img.heroimage {
        object-fit: none;
        object-position: 60% 0;
        max-height: 346px;
    }
    .contactme-container {    
        top: 200px;
        }
    img.contactme {
        width: 25vw;
    }




  }



  @media (max-width: 800px) {
    .literacysuperhero {    
        font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:30px;color:#ffff00;font-weight:bold;
        padding: 10px;
      }
    .hero {
        max-height: 352px;
    }
    .jackalopeman {
    margin-top: -2px;
    font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:18px;color:#ffffff;font-weight:bold;

    
    }
    .authorpoet {
        margin-top: -15px;
        font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;font-size:18px;color:#ffff00;font-weight:normal;

        
        }
    img.heroimage {
            object-fit: none;
            object-position: 50% 0;
            max-height: 352px;
        }
    .contactme-container {    
        padding: 10px;
        top: 100px;
        }
    img.contactme {
        width: 30vw;
    }

    .contact-box {

        float:none;

    }
  }



@media (max-width: 600px) {
    .literacysuperhero {    
        font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:18px;color:#ffff00;font-weight:bold;
        padding: 8px;
      }

    .hero {
        max-height: 162px;
    }
    .jackalopeman {

    margin-top: 0px;
    font-family:Tahoma,Arial,Helvetica,Sans-Serif;font-size:12px;color:#ffffff;font-weight:bold;

    
    }
    .authorpoet {
        margin-top: -10px;
        font-family:Vendana,Tahoma,Arial,Helvetica,Sans-Serif;font-size:12px;color:#ffff00;font-weight:normal;
        }
    img.heroimage {
        object-fit: none;
        object-position: 50% 0;
        max-height: 162px;
    }
    p.imbruce {
        line-height: 1.4;
        font-size:16px;color:#ffffff;

    }

    

  }