.block-container {
  background-color: #58D68D;
  border-radius: 5px;
  border-bottom:2px;
  border-right: 2px;
  border-left: 2px;
  border-top: 0px;
  padding: 0;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
  
  /* border-style: solid; */
  border-style: solid;
  border-color: #EEEEEE;
  /* margin-top: 5px; */
}
.header-container {

  background-color: #FF0000;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  border-top: 2px solid #EEEEEE;
}
p.header-element {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px;
  color: #EEEEEE;
  text-align: center;
  font-family: Vendana,Tahoma,Arial,Helvetica,Sans-Serif;
  font-size: 25px;
  font-weight: bold;
  margin-top:0px;
  vertical-align: middle;

}

.location-container {
  background-color: #036C81;
  padding: 10px;
  border-radius: 5px;
  font-family: Vendana,Tahoma,Arial,Helvetica,Sans-Serif;
  font-size: 1.4em;
  color: #ffffff;
  font-weight: bold;
}

.resources {
  justify-content:center;
  text-align:center;
  /* padding: 20px; */

}
.resource-container {
    vertical-align: middle;
    display: inline-block;

    align-items: center;
    border-radius:5px;
    background-color: #036C81;
    width: 280px;
    margin: 10px;
    padding: 10px;
    
  }
  
  .logo-container {
    background-color: #FFFFFF;
    border-radius:5px;
    flex: 1;
    display: flex;

    align-items: center;
    height: 150px;
    max-width: 90vw;
    padding: 10px;
    justify-content:center;

  }
  
  .logo-image {
    align-items: center;
    max-width: 100%;
    max-height: 100%;
  }
  
  .text-container{
    display: flex;
    align-items: center;
    height: 100px;
    max-width: 90vw;
    padding: 10px;
    position: relative;

  }
  p.customlinks {
    text-align: center;
    font-family: Tahoma,Arial,Helvetica,Sans-Serif;
    font-size: 1.4em;
    color: #ffffff;
    font-weight: bold;
    background-color: #036C81;

  }
  

